.fixed-top {
  position: sticky;
  top: 0;
  border-width: 0 0 1px;
  border-radius: 0;
  right: 0;
  left: 0;
}
.onboard-link-color {
  color: #176ffc !important;
  float: right;
  font-weight: bold;
}
.onboarding-cross-button {
  background: #fff;
  border: 1px solid #e4e7eb;
  color: #635BFF;
}
.onboarding-cross-button:hover {
  background-color: #635BFF !important;
  border: 1px solid #635BFF !important;
  color: #fff !important;
}
.add-organization-artwork-container {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f6f8f9;
  height: 100vh;
}
.link-color {
  color: #3b90f4 !important;
  margin: 30px 5px 0 !important;
  text-decoration: underline;
}

.login-img-container {
  margin: 0;
  justify-content: center;
  align-items: center;
  padding-top: 20vh;
}
.auth-artwork-text {
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  font-size: 24px;
  margin-top: -3vh;
  max-width: 70%;
}
.link-register {
  color: #66615b;
  font-size: 17px;
}

.login-button {
  background: linear-gradient(to right, #33ccae 0%, #00a99d 100%);
  height: 49px;
  font-size: 20px;
  font-weight: 400;
}
.register-button {
  background: linear-gradient(to right, #33ccae 0%, #00a99d 100%);
  height: 49px;
  font-size: 20px;
  font-weight: 400;
}
select {
  background-color: #0082d8;
  height: 100px;
}
.buttonStyle {
  background-color: #109c88;
}
.login-form {
  margin-top: 25px;
}
.login-form .form-group {
  margin-bottom: 15px;
}
.show-password-icon {
  width: 32px;
  position: absolute;
  cursor: pointer;
  left: 88%;
}
.register-form .form-group {
  margin-bottom: 18px;
}
.link-forgot {
  margin-bottom: 20px !important;
  color: #666;
}
.register {
  padding: 10px;
  margin: 20px 10px 15px;
  background: #fff;
}
.list_button {
  width: 10px;
  height: 10px;
}
@media (max-width: 1200px) {
  #leftImg {
    display: none !important;
 }
}
.loginRight {
  width: 493px;
  height: 300px;
  margin-left: 100px;
  margin-top: 162px;
}

a {
  margin: 0px 0px 0px !important;
}
.copyright {
  color: black !important;
}
.custom-loader .modal-content {
  background-color: transparent !important;
  box-shadow: none !important;
  margin-top: 30vh;
}
.custom-loader .modal-body {
  align-self: center !important;
}
.remove-default-mt {
  margin-top: 0px !important;
}
.remove-default-mb {
  margin-bottom: 0px !important;
}
.remove-default-uppercase {
  text-transform: none !important;
}
.replace-border-primary {
  border: 1px solid #51cbce !important;
}
.replace-border-default {
  border: 1px solid #66615b !important;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mb-10 {
  margin-bottom: 10px;
}
.ml-10 {
  margin-left: 10px;
}
.mr-10 {
  margin-right: 10px;
}
.span-mr-5 {
  margin-right: 5px;
}
.font-bold .modal-title, .font-bold {
  font-weight: bold !important;
}
input::file-selector-button {
  font-weight: 500;
  color: #FFF;
  padding: 0.6em 2em;
  background-color: #635BFF;
  border-radius: 6px;
  border: none;
  outline: none;
  cursor: pointer;
}
.form-control {
  font-size: 13px;
  margin-top: 0;
  padding: 5px;
  height: auto;
}
textarea.form-control {
  padding: 5px 10px !important;
}
.red-error {
  color: red !important;
}
.table-status-style {
  padding: 5px 10px;
  color: white;
  border-radius: 2px;
}
.table-header-style {
  font-size: 10px;
}
.span-screenshot-ml {
  color: #0082d8;
  margin-left: 5px;
}
.span-screenshot-ml:hover {
  color: blue;
  cursor: pointer;
}
.icon-screenshot-mr {
  margin-right: 5px;
}
.total-hours {
  color: #0082d8;
}
.total-hours-text {
  color: #8f9fae;
}
.get-started-modal .modal-body {
  padding: 20px 30px !important;
}
.get-started-modal {
  max-width: 600px !important;
}
.gray-color {
  color: #a8b5c0;
}
.project-data-table {
  margin-top: 25px;
}
.profile-edit-organizations {
  margin-top: 0 !important;
}
.default-email-button {
  color: #6c6c6c;
  margin: -6px 10px 0px 30px;
  padding: 4px 10px;
  height: 35px;
  width: 120px;
  font-size: 16px;
}
.default-email-button:hover {
  color: #6c6c6c !important;
  background: #d2d7db !important;
  border: 1px solid #d2d7db !important;
}
.hideText {
  visibility: hidden;
}
.time_picker {
  width: 300px;
  border: 1px solid #ccc;
  height: 38px;
  background-color: #fff;
  margin-left: 5px;
  margin-top: 0;
  padding: 10px;
  border-radius: 4px;
}
.button-time {
  margin-top: 25px;
  width: 90px;
  padding: 0;
  height: 40px;
  font-size: 14px;
}
.button-add {
  margin-top: 45px;
  width: 175px;
  padding: 0;
  height: 47px;
  font-size: 14px;
  margin-left: 10px;
}
@media (max-width: 400px) {
  .time_picker {
    width: 190px;
    border: none;
    height: 47px;
    background-color: #e9edf4;
 }
  .memberSearch {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
 }
  .button-time {
    width: 65px;
    text-align: center;
    font-size: 10px;
 }
  .button-add {
    width: 78px;
    text-align: center;
    font-size: 10px;
 }
  .rdt.rdtOpen .rdtPicker {
    opacity: 1;
    visibility: visible;
    margin-top: 29px !important;
    width: 209px !important;
 }
  .AddManual {
    text-align: center !important;
    margin-bottom: 20px;
 }
}
.rdt.rdtOpen .rdtPicker {
  opacity: 1;
  visibility: visible;
  margin-top: 29px !important;
  width: 306px;
}
.span-timesheet-daily {
  color: #0082d8;
}
.span-timesheet-daily:hover {
  color: blue;
  cursor: pointer;
}
.today-button-mt-weekly {
  margin-top: 28px !important;
}
.add-time-modal {
  max-width: 750px !important;
}
.add-time-modal .modal-body {
  padding: 20px 30px !important;
}
.add-time-modal .close, .add-project-modal .close {
  margin-top: -45px !important;
}
.add-time-footer {
  padding: 5px 15px !important;
}
.add-time-footer button {
  border-radius: 3px !important;
  background-color: #aaa !important;
  text-transform: capitalize;
  width: 120px;
  font-weight: 700;
  height: 47px;
  font-size: 14px;
}
.allNotes-modal {
  max-width: 1050px !important;
}
.allNotes-modal .modal-content {
  width: 90%;
  margin: 0 auto;
}
.allNotes-modal .modal-body {
  padding: 20px 30px !important;
}
.allNotes-modal .modal-body .quantity-notes {
  border-radius: 50%;
  font-size: 15px;
  text-align: center;
  width: 25px;
  height: 22px;
  color: #fff;
}
.date {
  width: 100%;
  height: 30px;
  border: none !important;
  background-color: #e9edf4 !important;
}
.AddManual {
  text-align: left;
}
.modalLabel {
  text-align: center !important;
  margin-top: 10px;
}
.modalReason {
  text-align: center !important;
  margin-top: 51px !important;
}
.textAreaManual {
  background-color: #e9edf4 !important;
  border: none !important;
  font-size: 15px;
}
.addMember-modal {
  max-width: 900px !important;
}
.addMember-modal .modal-body {
  padding: 40px 70px !important;
}
.top-buffer {
  text-align: justify;
  margin-bottom: 20px;
  width: 56px;
  height: 30px;
}
.LabelModalInvite {
  font-size: 14px;
}
.emailInput {
  width: 100%;
  height: 33px;
  background-color: #f0f1f3;
  border: none;
}
.emailInput:hover {
  border: solid 1px #bebebe !important;
  border-radius: 0% !important;
}
@media (max-width: 768px) {
  .top-buffer {
    text-align: justify;
    margin-top: 30px;
 }
}
@media (min-width: 1300px) {
  .pricingResponsiveShow {
    float: left;
 }
  .memberModalText {
    font-size: 0px !important;
 }
}
.manage-members {
  margin-top: 10px;
}
.editProject {
  font-size: 16px;
  color: #666;
}
.editProject:hover {
  cursor: pointer;
}
.pricingItem {
  font-size: 20px;
  margin-left: 5px;
}
.fontPricing {
  color: #18e2e2;
  text-shadow: 1px 1px 1px #ccc;
  font-size: 1.5em;
}
.listgroup {
  text-align: justify;
}
.PricingButton {
  background-color: #10bbbb;
  height: 50px;
  font-size: 18px;
}
.pricingIcon {
  color: #0ea8a8;
  font-weight: lighter;
}
.pricingIconNot {
  color: #8a8a8a;
}
@media only screen and (max-width: 768px) {
  .today-button-mt {
    margin-top: 10px !important;
 }
  .add-time-modal {
    max-width: 600px !important;
 }
  .manage-members {
    margin-top: 0px;
    float: left !important;
 }
}
.nav-tabs .nav-item .nav-link.active, .nav-tabs .nav-item .nav-link.active:hover, .nav-tabs .nav-item .nav-link.active:focus {
  background-color: transparent;
  border-bottom: solid 2px #00a99d;
}
.nav-tabs .nav-item .nav-link .active :hover {
  cursor: pointer;
}
.memberModalText {
  font-size: 20px;
}
.block-pdg {
  padding: 25px;
}
.nav-bar-user-avatar-box {
  width: 30px;
  height: 30px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  margin-right: 15px;
}
.nav-bar-user-avatar-box img {
  width: 100% !important;
  height: 100% !important;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}
.user-avatar-box {
  width: 85px;
  height: 85px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  margin-right: 15px;
}
.user-avatar-box img {
  width: 100% !important;
  height: 100% !important;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}
.username {
  font-size: 16px;
  color: #4d4d4d;
}
.inline-form-view {
  margin: 30px 0 0;
}
.inline-form-label, .form-control {
  color: #6c6c6c !important;
  font-size: 15px !important;
  display: inline-block;
  text-transform: none;
}

.save-button {
  margin-top: 25px;
  font-size: 15px !important;
  font-weight: normal !important;
}
.static {
  color: #bcbcbc !important;
}
.static .inline-form-label {
  color: #bcbcbc !important;
}
.static div {
  font-size: 15px !important;
}
.change_picture input {
  width: 90px;
}
/* This is only to temporally solve firefox image upload issue. Should be solved properly later. */

.project_status_span {
  padding: 7px;
  border-radius: 3px;
  text-transform: capitalize;
  display: inline-block;
  height: 35px;
  width: 84px;
  text-align: center;
}
.report-date-span {
  padding: 7px;
  border-radius: 3px;
  text-transform: capitalize;
  display: inline-block;
  text-align: center;
}
.archive_btn {
  background: none;
  border: 1px solid #666;
  color: #666;
  margin-left: 10px !important;
  height: 35px;
  padding: 10px 22px;
}
.table_header {
  color: #666;
}
.table_header .header_line {
  border-bottom: 1px solid #dcdbdb;
}
.table_header .header_line th {
  text-transform: capitalize;
}
.table_header .org-plan {
  padding-top: 13px;
}
.header_line {
  border-bottom: 1px solid #dcdbdb;
  letter-spacing: 0.035em;
}
.header_line th {
  text-transform: capitalize !important;
  color: #7c7c7c !important;
}
.btn-outline-secondary[disabled], .btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #fff !important;
}
.btn-outline-secondary[disabled], .btn-outline-secondary:disabled, .btn-outline-secondary.disabled, .disabled {
  pointer-events: auto !important;
  cursor: not-allowed !important;
}
.btn-outline-secondary[disabled]:hover, .btn-outline-secondary:disabled:hover, .btn-outline-secondary.disabled:hover, .disabled:hover {
  cursor: not-allowed !important;
}
.rdt {
  position: relative;
}
.rdt .styled-calendar {
  width: 100%;
}
.calendar {
  color: #00a99d;
  position: absolute;
  right: 10px;
  bottom: 6px;
  display: block;
  height: 20px;
  cursor: pointer;
}
@media screen and (max-width: 1550px) and (min-width: 1200px) {
  .fa-calendar:before {
    content: none;
 }
}
.modal-sm-title {
  font-size: 14px;
}
.table-responsive {
  overflow: auto;
}
th {
  letter-spacing: 0.03em;
  color: #595757;
}
.apps-data-table tbody td {
  color: #000;
  border-bottom: 1px solid #dee2e6 !important;
}
.dashboardTable {
  min-height: 407px;
  height: auto;
}
.screenshot-laptop-icon {
  position: relative;
  right: 2px;
  max-width: 35%;
  top: 3px;
}
.apps-data-body {
  max-height: 250px;
  overflow-x: auto;
}
.tb-wrapper .table-responsive {
  overflow-x: auto !important;
  max-height: 75px;
}
.tb-wrapper {
  overflow-x: initial !important;
}
cancel-btn {
  background: #aaa;
  border-radius: 0;
  width: 110px;
  height: 40px;
}
.timesheet_time_picker {
  height: 40px;
  margin-top: 0 !important;
  background: #fff !important;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  margin-left: 0 !important;
  color: #808080;
}
.manual-time-time-picker {
  height: 40px;
  margin-top: 0 !important;
  background: #fcfdfe !important;
  border: 1px solid #e4e7eb;
  border-radius: 4px;
  width: 100%;
  margin-left: 0 !important;
  color: #2f394e;
}
.manual-time-time-picker::placeholder {
  font-size: 14px;
}
.manual-time-time-picker:focus {
  outline: none;
}
.weekly-modal-header {
  text-align: left;
  padding-bottom: 0;
  border-bottom: 0;
  padding-left: 50px;
}
.close-btn-img {
  border: none;
  background: transparent;
  text-align: right;
  font-size: 24px;
  font-weight: 600;
  cursor: pointer;
  width: 30px;
  float: right;
  position: relative;
  top: 0px;
  z-index: 20;
}
.carousel-inner img {
  position: relative;
  top: -38px;
}
.btn-wrapper {
  padding: 0;
}
.btn-wrapper button {
  background: #635BFF;
  height: 30px;
  border-radius: 3px;
  line-height: 9px;
}
.message-quantity {
  font-size: 9px;
  padding: 1px 6px !important;
  background-color: #dc3545 !important;
}
.textArea {
  width: 100%;
  padding: 10px;
  height: 75px;
  border: 1px solid lightgrey;
  border-radius: 2px;
  resize: none;
}
.manualForm .invalid-feedback {
  display: block;
}
.weekday-checkbox {
  margin-right: 35px;
}
.weekday-checkbox input {
  margin-top: 25px;
  width: 20px;
  height: 20px;
}
.allWeek-checkbox {
  margin-bottom: 20px;
}
.allWeek-checkbox input {
  width: 20px;
  height: 20px;
  margin: 0 20px 0.5rem 0;
}
.allWeek-checkbox label {
  margin-left: 30px;
}
.scheduleForm .invalid-feedback {
  display: block;
}
.wrapper-dotted-text {
  display: flex;
  align-items: center;
  padding: 19px 7px !important;
}
.wrapper-dotted-text .dotted-text {
  text-overflow: ellipsis;
  width: 80px;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}
.wrapper-dotted-text button {
  margin-bottom: 0 !important;
  padding: 5px 15px;
}
.btn-container {
  display: flex;
  align-items: center;
}
.btn-container button {
  margin-bottom: 0 !important;
  background: #fff !important;
  border: 1px solid #7a7979;
  color: #7a7979;
  padding: 5px 15px;
}
.nav-tabs-cont .nav-tabs .nav-link {
  cursor: pointer !important;
  border-bottom: 2px solid #fff;
}
.btn-popup {
  font-size: 14px !important;
}
.members_button {
  width: 135px !important;
  background: linear-gradient(to right, #32cbae 0%, #0190aa 100%);
}
.lds-spinner {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-spinner div {
  transform-origin: 32px 32px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
 }
  100% {
    opacity: 0;
 }
}
.loader {
  width: 100%;
  height: auto;
  text-align: center;
  position: absolute;
  top: 40%;
}
.loader p {
  margin-top: 140px;
  color: #33ccae;
  font-size: 20px;
}
.client-modal button {
  font-size: 14px;
  padding: 0 !important;
}
.organization-modal button:last-child {
  width: 250px;
}
.invalidInput {
  border: 2px solid #ef505c;
}
.alert-login-page {
  background: #fadcde !important;
  color: #666;
  font-size: 18px;
  margin-top: 15px;
  text-align: left;
}
.success-active {
  width: 100%;
  height: auto;
  text-align: center;
  position: absolute;
}
.success-active p {
  margin-top: 140px;
  color: #33ccae;
  font-size: 30px;
}
.timezone .css-10nd86i {
  font-size: 15px !important;
}
.forgotPasswordPage .form-group .input-group-prepend .input-group-text, .forgotPasswordPage .form-group .input-group-append .input-group-text, .forgotPasswordPage .input-group .input-group-prepend .input-group-text, .forgotPasswordPage .input-group .input-group-append .input-group-text {
  padding: 10px !important;
}
.forgotPasswordPage .input-group .form-control:last-child, .forgotPasswordPage .input-group-btn:last-child
.dropdown-toggle, .forgotPasswordPage .input-group-btn:first-child
.btn:not(:first-child) {
  margin: 0;
}
.gm-style-iw.gm-style-iw-c {
  max-width: 654px;
  max-height: 206px;
  padding: 18px;
  background: #fff;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
.gm-style-iw.gm-style-iw-c .gm-style-iw-d {
  overflow: auto !important;
}
.gm-style-iw.gm-style-iw-c .gm-style-iw-d .calendar-pop-inner {
  padding: 10px 25px 10px 20px;
  background: #fff;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0;
}
.gm-style-iw.gm-style-iw-c .gm-style-iw-d .calendar-pop-inner p {
  margin-bottom: 0;
}
.gm-style-iw.gm-style-iw-c .gm-style-iw-d .calendar-pop-inner + div {
  padding: 15px 20px 1px 20px;
  background: #f2f3f4;
  margin-bottom: 20px;
}
.gm-style-iw.gm-style-iw-c .gm-style-iw-d .calendar-pop-inner .col {
  padding-left: 0;
}
.default-header {
  margin-left: 15px;
  font-size: 24px;
  color: #2f394e;
}
.jsx-693531783 {
  border-radius: 15px;
  width: 100%;
}
.stripeCard .card-header {
  width: 70%;
  margin: 0 auto;
  margin-top: 22px;
  color: #808080;
}
.stripeCard .card-body {
  padding: 5px 14px 12px 15px !important;
  flex: none !important;
}
.stripeCard .card-footer {
  width: 67.5%;
  margin: 0 auto;
  padding: 0 !important;
}
.stripeCard .stripeBtn {
  background: linear-gradient(to right, #33ccae 0%, #00a99d 100%);
  width: 100px;
  margin-top: 5px;
}
.stripeCard .stripeElem {
  width: 70%;
  margin: 0 auto;
  border: 1px solid #ddd;
  height: 40px;
  padding: 10px;
  border-radius: 6px;
}
.stripeCard label {
  margin-left: 150px;
}
.btn-disable button {
  color: grey !important;
}
.disable-button:focus {
  background: #fff !important;
  color: grey !important;
}
.btn-spec-hover {
  cursor: pointer;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 700 !important;
}
.btn-spec-hover:hover {
  background: linear-gradient(to right, #2ebfa5 0%, #008796 100%);
}
.nav-tabs .nav-link::after {
  border: none !important;
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
 ;
}
.nav-tabs .nav-link::before {
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
 ;
}
.nav-tabs .nav-item .nav-link {
  cursor: pointer !important;
  border-bottom: 2px solid #fff;
}
.border-inp {
  border: 1px solid;
  background-color: #fff;
  border-color: #ccc;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}
.border-top-none {
  border-top: none !important;
}
.schedule-modal .close {
  display: none !important;
}
#contact_part select {
  background: none !important;
}
.react-phone-number-input__icon {
  width: 2.74em;
  height: 2.43em;
  border: none;
}
.react-phone-number-input__icon .react-phone-number-input__icon-image {
  margin-top: 5px !important;
}
.btnDashboardSeeMore {
  background: #635BFF;
  border-radius: 3px;
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-top: 11px !important;
  padding-bottom: 11px !important;
  font-size: 13px;
  font-weight: 500;
}
.allTasks-modal {
  max-width: 80% !important;
}
.custom-header-button {
  align-self: flex-start;
  background: #635BFF;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: normal;
  margin-top: 0;
  border-radius: 3px;
}
.content-header-wrapper {
  display: flex;
}
.search-bar {
  background-color: #fff;
  border: 1px solid #c1c1c1;
}
.activity-report-card-column {
  padding-left: 30%;
}
.screenshot-notes-btn {
  width: 100% !important;
}
.dashboard-hours-img {
  padding: 3px 40px 8px 2px;
}
.payment-rate-th {
  width: 220px;
}
.lg-outer .lg-inner {
  top: -75px;
}
.lg-backdrop {
  background-color: #444;
}
.lg-outer .lg-image {
  max-height: 70%;
}
.lg-sub-html {
  background-color: rgba(43, 42, 42, 0.85);
  padding: 10px 106px;
  display: flex;
}
.lg-outer .lg-thumb-outer {
  background-color: #444;
}
.lg-outer .lg-toggle-thumb {
  background-color: #444;
  right: 0;
  left: 20px;
}
.lg-actions .lg-next, .lg-actions .lg-prev {
  margin-top: -90px;
}
.screenshot-desc-title {
  font-size: 13px !important;
  font-weight: bold;
  letter-spacing: 0.03rem;
  margin-right: 5px !important;
}
.screenshot-desc-text {
  font-size: 13px !important;
}
.custom-tab-style {
  justify-content: flex-start;
  padding-left: 17px;
}
#minimizeSidebar {
  background-color: #00a99d !important;
}
#minimizeSidebar:active {
  background-color: #33ccae !important;
}
#minimizeSidebar:hover {
  background-color: #33ccae !important;
}
.Toastify__toast {
  min-height: 50px !important;
  border-radius: 8px !important;
  box-shadow: 0 20px 70px 0 rgba(194, 204, 222, 0.6);
  padding-left: 15px;
}
.Toastify__toast--default {
  background: #62be96 !important;
  color: #fff !important;
  font-size: 14px;
}
.Toastify__toast--info {
  background: #62be96 !important;
  color: #fff !important;
  font-size: 14px;
}
.Toastify__toast--success {
  background: #62be96 !important;
  color: #fff !important;
  font-size: 14px;
}
.Toastify__toast--error {
  background: #ea6969 !important;
  color: #fff !important;
  font-size: 14px;
}
.Toastify__toast-container--bottom-right {
  bottom: 4em !important;
  width: fit-content;
}
.rbc-month-header {
  background: #f6f8f9;
  height: 45px;
}
.rbc-header {
  color: rgba(61, 77, 105, 0.7);
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}
.rbc-header span {
  font-size: 13px;
  font-weight: 600;
}
.rbc-header + .rbc-header {
  border-left: none;
}
.rbc-off-range-bg {
  background: white;
}
.rbc-off-range {
  color: rgba(61, 77, 105, 0.3);
}
.rbc-month-view {
  border: 1px solid #f6f8f9;
  margin-top: 20px;
}
.rbc-date-cell {
  font-size: 14px;
  text-align: center;
  padding-top: 4px;
}
.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #f6f8f9;
}
.rbc-month-row {
  border-top: 1px solid #f6f8f9 !important;
}
.rbc-today {
  background-color: white;
}
.rbc-date-cell.rbc-now {
  color: #635BFF;
}
.rbc-date-cell.rbc-now a {
  font-weight: 600;
}
.rbc-row-segment {
  display: flex;
  justify-content: center;
  align-items: center;
}
.monthly-timesheet-time {
  font-size: 14px;
  height: 36px;
  width: 90px;
  margin-top: 20px;
  padding: 0;
  cursor: default;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: none;
}
.monthly-timesheet-timespan {
  margin-right: 6px;
}
.monthly-timesheet-total {
  margin: 0;
  background: #d7dce2;
  text-align: center;
  border-radius: 3px;
  padding: 6px;
  width: 170px;
  float: right;
}
.rbc-row-content {
  z-index: 0;
}
.rbc-event.rbc-selected {
  background-color: white;
}
.rbc-event:focus {
  outline: none;
}
.monthly-timesheet-header {
  font-size: 20px;
}
.monthly-timesheet-calendar {
  height: 600px;
}
.monthly-timesheet-round-btn:hover {
  background-color: #0e877d !important;
}
.monthly-timesheet-round-btn:focus {
  background-color: #00a99d !important;
}
.seven-cols .col-md-1, .seven-cols .col-sm-1, .seven-cols .col-lg-1 {
  flex: 0 0 14.2857142857%;
  max-width: 14.2857142857%;
}
.biwk-timesheet-date {
  text-align: center;
  width: 100%;
  font-weight: 700;
  font-size: 15px;
  color: #666;
}
.biwk-timesheet-hour {
  display: flex;
  justify-content: center;
  height: 35px;
  width: 100%;
  margin-bottom: 20px;
}
.biwk-timesheet-hour-span {
  min-width: 130px;
  text-align: center;
  border: 2px solid #d3d3d3;
  height: 35px;
  border-radius: 9px;
  padding-top: 5px;
  font-weight: normal;
  font-size: 16px;
}
.biwk-timesheet-date-time {
  margin-top: 15px;
}
.biwk-timesheet-date-range {
  color: #666;
}
.biwk-timesheet-total {
  margin: 0;
  background: #d7dce2;
  text-align: center;
  border-radius: 9px;
  padding: 6px;
  width: 140px;
  float: right;
}
.clock-icon {
  margin-left: 5px;
  color: rgba(50, 168, 170, 0.9);
}
.DateRangePicker {
  height: 40px;
  max-width: 100%;
  border-radius: 6px;
  width: fit-content;
}
.DateRangePickerInput {
  height: 38px;
  width: 90%;
  display: flex;
  border-radius: 6px;
  border: 1px solid #e4e7eb;
}
.DateRangePickerInput:hover {
  border-color: #635BFF;
  box-shadow: 0 0 0 4px #eaf9f7;
}
.DateRangePickerInput:focus {
  border-color: #635BFF;
  outline: none;
  box-shadow: 0 0 0 4px #eaf9f7;
}
.DateInput {
  height: 36px;
  width: 100px;
  margin-left: 5px;
  margin-top: 1px;
}
.DateInput_input {
  height: 36px;
  font-size: 13px;
  color: #1f2939;
}
.DateInput_input__focused {
  border-bottom: 2px solid #635BFF;
}
.CalendarMonth_caption {
  color: #635BFF;
}
.DayPicker_weekHeader {
  color: #635BFF;
}
.DateRangePickerInput_calendarIcon {
  margin: 0 5px;
}
.CalendarDay {
  font-size: 13px;
}
.CalendarDay__default {
  border: 1px solid #e4e7eb;
}
.CalendarDay__selected {
  background: #635BFF;
  border: 1px double #635BFF;
}
.CalendarDay__selected_span {
  background: #4cdbcb;
  border: 1px double #3bcfbe;
}
.date-range-footer {
  display: flex;
  justify-content: space-between;
  padding: 5px 20px 15px;
}
.SingleDatePicker {
  width: 100%;
  height: 40px;
  border-radius: 6px;
}
.SingleDatePickerInput {
  height: 40px;
  display: flex;
  border-radius: 6px;
  border: 1px solid #e4e7eb;
  margin-top: 1px;
}
.SingleDatePickerInput:hover {
  border-color: #635BFF;
  box-shadow: 0 0 0 4px #eaf9f7;
}
.SingleDatePickerInput:focus {
  border-color: #635BFF;
  outline: none;
  box-shadow: 0 0 0 4px #eaf9f7;
}
.SingleDatePickerInput .DateInput {
  height: 38px;
  margin: 0;
  border-radius: 6px;
  width: 100% !important;
  padding-left: 5px;
}
.SingleDatePickerInput .DateInput_input {
  height: 38px;
  width: 100%;
  font-size: 13px;
  color: #1f2939;
}
.SingleDatePickerInput .DateInput_input::placeholder {
  color: rgba(61, 77, 105, 0.7);
}
.SingleDatePickerInput .DateInput_input .CalendarMonth_caption {
  color: #635BFF;
}
.SingleDatePickerInput .DateInput_input .DayPicker_weekHeader {
  color: #635BFF;
}
.SingleDatePickerInput .DateInput_input__focused {
  border-bottom: 2px solid #635BFF;
}
.SingleDatePickerInput_arrow {
  margin: 10px 7px;
  font-size: 16px;
}
.offer-navbar {
  background: white;
  border-bottom: 2px solid #f6f6f6;
  z-index: 10;
  position: fixed;
  width: 100%;
}
.offer-navbar .nav-item {
  margin-right: 15px;
  font-weight: bold;
  color: #283b5c;
  cursor: pointer;
}
.offer-navbar .nav-item:hover .nav-link {
  color: white !important;
  transform: translateY(-1px);
  background-color: #3db3a9;
  border-radius: 5px;
}
.offer-navbar-container {
  width: 80%;
}
.black-friday-wrapper {
  padding-top: 30px;
}
.black-friday-image {
  width: 75%;
  margin-top: -40px;
}
.discount-info {
  color: #283b5c;
  font-size: 22px;
  text-align: center;
  padding: 0px 20px;
}
.discount-page-video {
  margin-top: 120px;
}
.countdown-timer-units {
  font-weight: bold;
}
.navbar .navbar-toggler {
  height: 37px;
  padding-right: 34px;
  padding-left: 5px;
}
.settings-button {
  background: #635BFF;
  color: white;
  font-size: 14px;
  border-radius: 6px;
  padding: 10px;
  font-weight: normal;
}
.go-back-button {
  height: 30px;
  width: 30px;
  cursor: pointer;
}
.settings-content-header {
  font-size: 20px;
  margin: 0;
  color: #3b3f47;
}
.upgrade-plan-card {
  padding: 30px 40px;
  border: 1px solid #c2cce1;
  border-radius: 10px;
  box-shadow: none;
}
.progress-vertical {
  width: 10px;
  min-height: 145px;
  display: flex;
  align-items: flex-end;
  margin-right: 0px;
  float: left;
  border-radius: 5px;
  background: rgba(181, 181, 181, 0.3);
}
.progress-bar-vertical {
  width: 100%;
  height: 0;
  border-radius: 5px;
  -webkit-transition: height 0.6s ease;
  -o-transition: height 0.6s ease;
  transition: height 0.6s ease;
}
.progress-bar {
  border-radius: 5px;
  -webkit-transition: height 0.6s ease;
  -o-transition: height 0.6s ease;
  transition: height 0.6s ease;
}
.clock-icon-white {
  filter: brightness(0) saturate(100%) invert(99%) sepia(1%) saturate(0%) hue-rotate(52deg) brightness(108%) contrast(101%);
}
.trial-info {
  color: #fff;
  padding: 5px 10px;
  border-radius: 6px;
  font-weight: bold;
  cursor: pointer;
}
.progress-bar-workspace {
  background: #635BFF !important;
}
.react-tel-input .form-control {
  height: 40px !important;
  width: 100% !important;
  border: 1px solid #e4e7eb !important;
}
.react-tel-input .form-control:hover {
  border-color: #635BFF !important;
  box-shadow: 0 0 0 4px #eaf9f7;
}
.react-tel-input .form-control:focus {
  border-color: #635BFF !important;
  box-shadow: 0 0 0 4px #eaf9f7;
}
.react-tel-input .flag-dropdown {
  background-color: #f6f6f6 !important;
  border: 1px solid #e4e7eb !important;
}
.nav-org-dropdown {
  display: flex;
  align-items: center;
  min-width: 200px;
  height: 42px;
  border: 1px solid #c2cce1;
  border-radius: 8px;
  padding: 6px 10px;
}
.nav-org-dropdown-menu {
  width: 100%;
  top: 8px !important;
  left: 10px !important;
}
.dropdown-menu .dropdown-item:hover {
  background-color: #e4e7eb !important;
  color: #2f394e !important;
}
.nav-selected-org {
  width: 100%;
  padding: 0;
  font-size: 14px !important;
  color: #2f394e !important;
  display: flex;
  align-items: center;
}
/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
}
/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  display: grid;
  grid-template-columns: 90% 35px;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  color: #3d4d69;
  cursor: pointer;
  padding: 15px;
  border: 1px solid #e4e7eb;
  border-radius: 10px;
  outline: none;
}
.accordion:focus {
  outline: none;
}
/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion:hover, .active {
  background-color: #eaf9f7;
  border: 1px solid transparent;
}
/* Style the accordion content title */
.accordion__title {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #3d4d69;
  margin: 5px 0;
}
/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
 /* background-color: white;
  */
  overflow: auto;
  transition: max-height 0.6s ease;
  overflow: hidden;
}
/* Style the accordion content text */
.accordion__text {
  font-weight: 400;
  font-size: 14px;
  padding: 18px 18px 18px 0px;
 /* color: rgba(47, 57, 78, 0.7);
  */
  color: #3d4d69;
}
/* Fix tab content style for accordion css */
.tab-content
.active {
  background: transparent;
}
.paddle-checkout {
  display: none;
  width: 100%;
}
.paddle-checkout.loaded {
  display: block;
  animation: 0.4s ease 0s 1 normal forwards running fadeIn;
}
.modal-backdrop.show {
  opacity: 0.1;
}
.navlinkActive {
  background-color: transparent;
}